#menu_btn {
  margin-right: 8px;
  margin-top: 1px;
  opacity: 0.5;
}

#mobile_menu {
  padding: 16px;
  background: var(--color-header);
  line-height: 1.5rem;
  overflow: auto;
}
#mobile_menu_contents {
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (min-width: 601px) {
  #mobile_menu {
    grid-area: canvas;
    padding-top: 80px;
  }

  body[data-pattern] #mobile_menu {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  #mobile_menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-width: 400px;
    z-index: 5;
    transition: transform 200ms ease-out;
    box-shadow: 3px 0 7px rgba(0 0 0 / 40%);
    transform: translateX(-100%);
  }
}

#mobile_menu.open {
  transform: translateX(0);
}

#mobile_menu_header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#mobile_menu_header h1 {
  flex: 1;
  font-size: 24px;
}

#mobile_menu h2 {
  font-size: 14px;
  color: var(--logo2);
  text-transform: uppercase;
  font-weight: 700;
}

#mobile_menu_header div {
  flex: 0;
}

#mobile_menu ol {
  margin: 0;
  padding: 0 16px;
}

#mobile_menu ol li {
  margin-bottom: 16px;
}

#mobile_menu ol li::marker {
  color: var(--color-lighter);
}

#mobile_menu section {
  margin-top: 16px;
  border-top: solid 1px rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 0 rgb(255 255 255 / 15%) inset;
}

#menu_links {
  list-style: none;
  margin: 0;
  padding: 0;
}

#menu_links li {
  padding: 8px 16px;
  border-top: var(--border-lighter);
}

#menu_links a {
  text-decoration: none;
  font-size: 1rem;
}

.menu_link_img {
  width: 24px;
  margin-inline-start: 16px;
  float: right;
}
