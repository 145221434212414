* {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

body {
  background: var(--color-canvas-background);
  color: var(--color-text);
  font-family: Roboto, sans-serif;
}

main {
  display: grid;
  height: 100%;
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-template-rows: var(--header-height) var(--select-height) 1fr;
  grid-template-areas:
    'header canvas'
    'pattern_select canvas'
    'config canvas';
}

@media only screen and (min-width: 601px) {
  body[data-pattern] main {
    grid-template-columns: var(--sidebar-width) 1fr;
    grid-template-rows: var(--header-height) var(--select-height) 1fr var(
        --controls-height
      );
    grid-template-areas:
      'header canvas'
      'pattern_select canvas'
      'config canvas'
      'controls player';
  }
}

button {
  font-family: Roboto;
}
#sidebar_form {
  border-right: var(--border-light);
  max-height: 100%;
  grid-area: config;
  overflow: hidden;
  background: var(--color-config);
  box-shadow: 1px 0 0px black;
}

#main_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: var(--color-header);
  border-right: var(--border-light);
  align-items: center;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
  position: relative;
  z-index: 2;
  padding: 0 var(--horizontal-padding);
}

h1 {
  margin: 0;
  flex-grow: 0;
  padding: 0;
  display: flex;
  gap: 0.5em;
  font-size: 21px;
  font-weight: 500;
}

.logo1 {
  color: var(--logo1);
}
.logo2 {
  color: var(--logo2);
}

canvas, svg {
  height: 100%;
  width: 100%;
}

a {
  color: var(--color-link);
  font-size: 14px;
}

a:hover {
  text-decoration: underline;
  color: var(--color-link-hover);
}
#canvas_panel {
  grid-area: canvas;
  text-align: center;
  overflow: hidden;
}
#canvas_panel.overflow {
  overflow: auto;
}

.small_only, .large_only {
  display: none !important;
}
