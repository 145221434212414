:root {
  --color-accent: #ff218f;
  --color-buttons-dialog: #212124;
  --color-canvas-background: #0e0e0e;
  --color-config: #212124;
  --color-gray3: #262629;
  --color-header: #2b2b31;
  --color-lighter: #ffffff87;
  --color-link: #68c0ff;
  --color-link-hover: #85ccff;
  --color-player: #222226;
  --color-text: White;
  --color-text-hover: #85ccff;

  --horizontal-padding: 16px;
  --vertical-padding: 12px;

  --logo1: #9892ff;
  --logo1-active: #c5c2ff;
  --logo2: #d4c1ff;

  --border-light: solid 1px #3a3a3a;
  --border-lighter: dotted 1px #505050;

  --circular_button_width: 38px;
  --controls-height: 50px;
  --header-height: 45px;
  --select-height: 49px;
  --sidebar-form-padding-top: 16px;
  --sidebar-width: 270px;
  --player-mobile-height: 40px;
  --responsive-width: 600px;
}
