@font-face {
  font-family: 'string_art_studio';
  src:
    url('fonts/string_art_studio.ttf?fmveaw') format('truetype'),
    url('fonts/string_art_studio.woff?fmveaw') format('woff'),
    url('fonts/string_art_studio.svg?fmveaw#string_art_studio') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'string_art_studio' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pause:before {
  content: "\e90b";
}
.icon-play:before {
  content: "\e90c";
}
.icon-arrow_left:before {
  content: "\e908";
}
.icon-arrow_right:before {
  content: "\e909";
}
.icon-caret_down:before {
  content: "\e90a";
}
.icon-fullscreen:before {
  content: "\e900";
}
.icon-download:before {
  content: "\e901";
}
.icon-hamburger:before {
  content: "\e902";
}
.icon-options:before {
  content: "\e903";
}
.icon-refresh:before {
  content: "\e904";
}
.icon-share:before {
  content: "\e905";
}
.icon-thumbnails:before {
  content: "\e906";
}
.icon-x:before {
  content: "\e907";
}
