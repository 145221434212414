.margin-top {
  margin-top: 16px;
}

#controls_panel {
  padding: 16px var(--horizontal-padding);
  overflow-y: auto;
  height: 100%;
}

#size_controls {
  margin-top: 16px;
}

label {
  font-size: 14px;
  font-weight: 500;
}

.control {
  margin-top: 16px;
  position: relative;
}

#controls > .control:first-child {
  margin-top: 0;
}

form label {
  display: block;
}

input[type='checkbox'] + label {
  display: inline-block;
  margin-top: 0;
  position: relative;
  top: -1px;
  margin-left: 4px;
}

input[type='number'],
input[type='text'] {
  vertical-align: bottom;
  height: 24px;
  margin-right: 4px;
  margin-top: 2px;
}

fieldset {
  border: var(--border-light);
  background: rgba(255, 255, 255, 0.03);
  border-radius: 9px;
}

fieldset > legend {
  cursor: pointer;
  opacity: 0.8;
}

fieldset > legend:hover {
  opacity: 1;
}

fieldset .control:first-child {
  margin-top: 8px;
}

fieldset.minimized {
  border: none;
  padding: 0;
  background: none;
}

fieldset.minimized > legend {
  padding: 0;
}

fieldset.minimized > div {
  display: none;
}

legend::before {
  font-family: 'string_art_studio';
  content: '\e90a';
  display: inline-block;
  margin-inline-end: 2px;
  font-size: 20px;
  opacity: 0.7;
  vertical-align: top;
}

fieldset.minimized > legend::before {
  transform: rotateZ(-90deg);
}

input[type='range'] + .control_input_value {
  position: absolute;
  right: 0;
  top: 2px;
}

select {
  width: 100%;
  padding: 4px;
  margin-bottom: 8px;
  margin-top: 4px;
}

.control_input_value {
  display: inline-block;
  position: relative;
  top: -3px;
  margin-inline-start: 2px;
  font-size: 12px;
}

#size_custom {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  max-width: 270px;
  margin-bottom: 16px;
}

#size_custom > div {
  flex: 1;
}
#size_custom input {
  width: 100%;
}
