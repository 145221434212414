input[type='color'] {
  padding: 0;
  border: none;
  background: none;
  position: relative;
  top: 2px;
}

input[type='number'],
input[type='text'] {
  padding: 4px;
}

input[type='range'] {
  width: 100%;
}
