#buttons {
  position: relative;
  grid-area: 'controls';
  border-right: var(--border-light);
  background: var(--color-header);
  box-shadow: 0 -2px 6px rgb(0 0 0 / 30%);
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 var(--horizontal-padding);
}

#buttons .btn {
  margin: 0;
}

.btn {
  height: 30px;
  width: 100%;
  border-radius: 15px;
  border: none;
  background: #0375fb;
  color: white;
  cursor: pointer;
  margin-bottom: 8px;
}

.btn:hover {
  background: #3592ff;
}

.btn_subdued {
  background: #c5c5c5;
  color: Black;
}

.btn_subdued:hover {
  background: #efefef;
  color: Black;
}

.circular_btn {
  width: var(--controls-height);
  height: var(--controls-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
  font-size: 28px;
}

.circular_btn:hover {
  background: rgba(0, 0, 0, 0.3);
}

.circular_btn img {
  width: 18px;
  height: 18px;
}

.circular_btn.active {
  box-shadow: 1px 1px 7px black inset;
  padding: 0;
  background: rgb(0 0 0 / 30%);
}

.circular_btn.active i {
  transform: scale(0.95);
}

#buttons_dialogs {
  position: absolute;
  z-index: 2;
  bottom: 100%;
  height: calc(100vh - var(--controls-height) - var(--select-height));
  width: 100%;
  overflow: hidden;
}

.buttons_dialog {
  --buttons-dialog-border-radius: 10px;
  position: fixed;
  bottom: var(--controls-height);
  z-index: 1;
  background: var(--color-buttons-dialog);
  transition: transform 200ms ease-out;
  transform: translateY(100%);
  width: var(--sidebar-width);
  padding: var(--vertical-padding) var(--horizontal-padding);
  padding-top: calc(2 * var(--buttons-dialog-border-radius));
  box-shadow: 0 -2px 7px rgb(0 0 0 / 70%);
  border-top: var(--border-light);
}

.buttons_dialog.open {
  transform: translateY(0);
}

.btn-icon {
  border: none;
  background: none;
  color: White;
  font-size: 22px;
}
