@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap";
:root {
  --color-accent: #ff218f;
  --color-buttons-dialog: #212124;
  --color-canvas-background: #0e0e0e;
  --color-config: #212124;
  --color-gray3: #262629;
  --color-header: #2b2b31;
  --color-lighter: #ffffff87;
  --color-link: #68c0ff;
  --color-link-hover: #85ccff;
  --color-player: #222226;
  --color-text: White;
  --color-text-hover: #85ccff;

  --horizontal-padding: 16px;
  --vertical-padding: 12px;

  --logo1: #9892ff;
  --logo1-active: #c5c2ff;
  --logo2: #d4c1ff;

  --border-light: solid 1px #3a3a3a;
  --border-lighter: dotted 1px #505050;

  --circular_button_width: 38px;
  --controls-height: 50px;
  --header-height: 45px;
  --select-height: 49px;
  --sidebar-form-padding-top: 16px;
  --sidebar-width: 270px;
  --player-mobile-height: 40px;
  --responsive-width: 600px;
}

* {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

body {
  background: var(--color-canvas-background);
  color: var(--color-text);
  font-family: Roboto, sans-serif;
}

main {
  display: grid;
  height: 100%;
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-template-rows: var(--header-height) var(--select-height) 1fr;
  grid-template-areas:
    'header canvas'
    'pattern_select canvas'
    'config canvas';
}

@media only screen and (min-width: 601px) {
  body[data-pattern] main {
    grid-template-columns: var(--sidebar-width) 1fr;
    grid-template-rows: var(--header-height) var(--select-height) 1fr var(
        --controls-height
      );
    grid-template-areas:
      'header canvas'
      'pattern_select canvas'
      'config canvas'
      'controls player';
  }
}

button {
  font-family: Roboto;
}
#sidebar_form {
  border-right: var(--border-light);
  max-height: 100%;
  grid-area: config;
  overflow: hidden;
  background: var(--color-config);
  box-shadow: 1px 0 0px black;
}

#main_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: var(--color-header);
  border-right: var(--border-light);
  align-items: center;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
  position: relative;
  z-index: 2;
  padding: 0 var(--horizontal-padding);
}

h1 {
  margin: 0;
  flex-grow: 0;
  padding: 0;
  display: flex;
  gap: 0.5em;
  font-size: 21px;
  font-weight: 500;
}

.logo1 {
  color: var(--logo1);
}
.logo2 {
  color: var(--logo2);
}

canvas, svg {
  height: 100%;
  width: 100%;
}

a {
  color: var(--color-link);
  font-size: 14px;
}

a:hover {
  text-decoration: underline;
  color: var(--color-link-hover);
}
#canvas_panel {
  grid-area: canvas;
  text-align: center;
  overflow: hidden;
}
#canvas_panel.overflow {
  overflow: auto;
}

.small_only, .large_only {
  display: none !important;
}

#player {
  grid-area: player;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-player);
  --player_text_width: 80px;
  --player-gap: 10px;
}

#player_text {
  display: flex;
  align-items: center;
  justify-content: end;
}

#player #pause_btn {
  display: none;
}
#player.playing #play_btn {
  display: none;
}
#player.playing #pause_btn {
  display: inline;
}

#player_buttons i {
  font-size: 22px;
}

#player_controls {
  gap: var(--player-gap);
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 800px;
}

#player_track {
  display: flex;
  align-items: center;
  flex: 1;
}

#player_position {
  width: 100%;
  margin: 0;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0;
}

#player_position::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #ff4500;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

#player_buttons .circular_btn {
  background: none;
}

@media only print {
  .noprint {
    display: none;
  }

  main {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'canvas';
  }

  canvas, svg {
    height: 100%;
  }
}

.margin-top {
  margin-top: 16px;
}

#controls_panel {
  padding: 16px var(--horizontal-padding);
  overflow-y: auto;
  height: 100%;
}

#size_controls {
  margin-top: 16px;
}

label {
  font-size: 14px;
  font-weight: 500;
}

.control {
  margin-top: 16px;
  position: relative;
}

#controls > .control:first-child {
  margin-top: 0;
}

form label {
  display: block;
}

input[type='checkbox'] + label {
  display: inline-block;
  margin-top: 0;
  position: relative;
  top: -1px;
  margin-left: 4px;
}

input[type='number'],
input[type='text'] {
  vertical-align: bottom;
  height: 24px;
  margin-right: 4px;
  margin-top: 2px;
}

fieldset {
  border: var(--border-light);
  background: rgba(255, 255, 255, 0.03);
  border-radius: 9px;
}

fieldset > legend {
  cursor: pointer;
  opacity: 0.8;
}

fieldset > legend:hover {
  opacity: 1;
}

fieldset .control:first-child {
  margin-top: 8px;
}

fieldset.minimized {
  border: none;
  padding: 0;
  background: none;
}

fieldset.minimized > legend {
  padding: 0;
}

fieldset.minimized > div {
  display: none;
}

legend::before {
  font-family: 'string_art_studio';
  content: '\e90a';
  display: inline-block;
  margin-inline-end: 2px;
  font-size: 20px;
  opacity: 0.7;
  vertical-align: top;
}

fieldset.minimized > legend::before {
  transform: rotateZ(-90deg);
}

input[type='range'] + .control_input_value {
  position: absolute;
  right: 0;
  top: 2px;
}

select {
  width: 100%;
  padding: 4px;
  margin-bottom: 8px;
  margin-top: 4px;
}

.control_input_value {
  display: inline-block;
  position: relative;
  top: -3px;
  margin-inline-start: 2px;
  font-size: 12px;
}

#size_custom {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  max-width: 270px;
  margin-bottom: 16px;
}

#size_custom > div {
  flex: 1;
}
#size_custom input {
  width: 100%;
}

#buttons {
  position: relative;
  grid-area: 'controls';
  border-right: var(--border-light);
  background: var(--color-header);
  box-shadow: 0 -2px 6px rgb(0 0 0 / 30%);
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 var(--horizontal-padding);
}

#buttons .btn {
  margin: 0;
}

.btn {
  height: 30px;
  width: 100%;
  border-radius: 15px;
  border: none;
  background: #0375fb;
  color: white;
  cursor: pointer;
  margin-bottom: 8px;
}

.btn:hover {
  background: #3592ff;
}

.btn_subdued {
  background: #c5c5c5;
  color: Black;
}

.btn_subdued:hover {
  background: #efefef;
  color: Black;
}

.circular_btn {
  width: var(--controls-height);
  height: var(--controls-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
  font-size: 28px;
}

.circular_btn:hover {
  background: rgba(0, 0, 0, 0.3);
}

.circular_btn img {
  width: 18px;
  height: 18px;
}

.circular_btn.active {
  box-shadow: 1px 1px 7px black inset;
  padding: 0;
  background: rgb(0 0 0 / 30%);
}

.circular_btn.active i {
  transform: scale(0.95);
}

#buttons_dialogs {
  position: absolute;
  z-index: 2;
  bottom: 100%;
  height: calc(100vh - var(--controls-height) - var(--select-height));
  width: 100%;
  overflow: hidden;
}

.buttons_dialog {
  --buttons-dialog-border-radius: 10px;
  position: fixed;
  bottom: var(--controls-height);
  z-index: 1;
  background: var(--color-buttons-dialog);
  transition: transform 200ms ease-out;
  transform: translateY(100%);
  width: var(--sidebar-width);
  padding: var(--vertical-padding) var(--horizontal-padding);
  padding-top: calc(2 * var(--buttons-dialog-border-radius));
  box-shadow: 0 -2px 7px rgb(0 0 0 / 70%);
  border-top: var(--border-light);
}

.buttons_dialog.open {
  transform: translateY(0);
}

.btn-icon {
  border: none;
  background: none;
  color: White;
  font-size: 22px;
}

#pattern_link {
  flex: 0;
  white-space: nowrap;
}

#pattern_select_panel {
  grid-area: pattern_select;
  position: relative;
  padding: var(--vertical-padding) var(--horizontal-padding);
  background: var(--color-gray3);
  border-bottom: var(--border-light);
  border-right: var(--border-light);
  flex: 1;
  display: flex;
  align-items: center;
}

#pattern_select_panel.minimized #pattern_select_btn::after {
  transform: rotateZ(-90deg) translateX(1px);
}

#pattern_select_panel.minimized #pattern_select_dropdown {
  display: none;
}

#pattern_select_btn {
  border: none;
  background: none;
  font-size: 24px;
  font-weight: 300;
  color: var(--color-text);
  padding: 0;
  cursor: pointer;
  flex: 1;
  text-align: left;
}

#pattern_select_btn:hover {
  color: var(--color-text-hover);
}

#pattern_select_btn::after {
  font-family: 'string_art_studio';
  content: '\e90a';
  display: inline-block;
  margin-inline-start: 4px;
  font-size: 24px;
  position: relative;
  top: 4px;
  transform: rotateZ(0);
  transition: transform 200ms ease-out;
  opacity: 0.7;
}

#pattern_select_dropdown {
  position: absolute;
  width: 100%;
  background: var(--color-gray3);
  z-index: 2;
  padding: 0 16px 16px;
  box-shadow: -5px 9px 8px rgb(0 0 0 / 50%);
  max-height: calc(
    100vh - var(--select-height) - var(--header-height) - var(--controls-height)
  );
  overflow: auto;
  left: 0;
  top: 100%;
}

#pattern_select_thumbnails_title {
  text-align: center;
  border-top: var(--border-light);
  border-bottom: var(--border-light);
  color: rgb(255 255 255 / 30%);
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px 0;
  font-weight: 400;
  margin-top: 0;
}

#pattern_select_thumbnails {
  list-style: none;
  margin: 0;
  padding: 0;
  column-width: 100px;
}

#pattern_select_thumbnails li {
  display: inline-block;
  margin-bottom: 8px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

#pattern_select_thumbnails a {
  display: inline-block;
  cursor: pointer;
  transition: transform 200ms ease-out;
}

#pattern_select_thumbnails a:hover {
  transform: scale(1.1);
}

#pattern_select_dropdown_instructions {
  padding: 16px 0;
  border-top: solid 1px rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 0 rgb(255 255 255 / 15%) inset;
}

#pattern_select_dropdown_instructions a {
  display: block;
  text-align: center;
}

@font-face {
  font-family: 'string_art_studio';
  src:
    url(string_art_studio.19f87e17.ttf) format('truetype'),
    url(string_art_studio.319bd9b7.woff) format('woff'),
    url(string_art_studio.425770e4.svg#string_art_studio) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'string_art_studio' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pause:before {
  content: "\e90b";
}

.icon-play:before {
  content: "\e90c";
}

.icon-arrow_left:before {
  content: "\e908";
}

.icon-arrow_right:before {
  content: "\e909";
}

.icon-caret_down:before {
  content: "\e90a";
}

.icon-fullscreen:before {
  content: "\e900";
}

.icon-download:before {
  content: "\e901";
}

.icon-hamburger:before {
  content: "\e902";
}

.icon-options:before {
  content: "\e903";
}

.icon-refresh:before {
  content: "\e904";
}

.icon-share:before {
  content: "\e905";
}

.icon-thumbnails:before {
  content: "\e906";
}

.icon-x:before {
  content: "\e907";
}

input[type='color'] {
  padding: 0;
  border: none;
  background: none;
  position: relative;
  top: 2px;
}

input[type='number'],
input[type='text'] {
  padding: 4px;
}

input[type='range'] {
  width: 100%;
}

#menu_btn {
  margin-right: 8px;
  margin-top: 1px;
  opacity: 0.5;
}

#mobile_menu {
  padding: 16px;
  background: var(--color-header);
  line-height: 1.5rem;
  overflow: auto;
}
#mobile_menu_contents {
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (min-width: 601px) {
  #mobile_menu {
    grid-area: canvas;
    padding-top: 80px;
  }

  body[data-pattern] #mobile_menu {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  #mobile_menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-width: 400px;
    z-index: 5;
    transition: transform 200ms ease-out;
    box-shadow: 3px 0 7px rgba(0 0 0 / 40%);
    transform: translateX(-100%);
  }
}

#mobile_menu.open {
  transform: translateX(0);
}

#mobile_menu_header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#mobile_menu_header h1 {
  flex: 1;
  font-size: 24px;
}

#mobile_menu h2 {
  font-size: 14px;
  color: var(--logo2);
  text-transform: uppercase;
  font-weight: 700;
}

#mobile_menu_header div {
  flex: 0;
}

#mobile_menu ol {
  margin: 0;
  padding: 0 16px;
}

#mobile_menu ol li {
  margin-bottom: 16px;
}

#mobile_menu ol li::marker {
  color: var(--color-lighter);
}

#mobile_menu section {
  margin-top: 16px;
  border-top: solid 1px rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 0 rgb(255 255 255 / 15%) inset;
}

#menu_links {
  list-style: none;
  margin: 0;
  padding: 0;
}

#menu_links li {
  padding: 8px 16px;
  border-top: var(--border-lighter);
}

#menu_links a {
  text-decoration: none;
  font-size: 1rem;
}

.menu_link_img {
  width: 24px;
  margin-inline-start: 16px;
  float: right;
}

@media only screen and (max-width: 600px) {
  main {
    grid-template-columns: 1fr;
    grid-template-rows: var(--select-height) 1fr;
    grid-template-areas:
      'pattern_select'
      'canvas';
  }

  body[data-pattern] main {
    grid-template-columns: 1fr;
    grid-template-rows: var(--select-height) 1fr var(--player-mobile-height) var(
        --controls-height
      );
    grid-template-areas:
      'pattern_select'
      'canvas'
      'player'
      'controls';
  }

  .dialog_sidebar_form main {
    grid-template-rows: var(--select-height) 100vw;
    grid-template-areas:
      'pattern_select'
      'canvas';
  }

  body[data-pattern].dialog_sidebar_form main {
    grid-template-columns: 1fr;
    grid-template-rows: var(--select-height) 100vw 1fr var(--controls-height);
    grid-template-areas:
      'pattern_select'
      'canvas'
      'config'
      'controls';
  }

  #main_header {
    display: none;
  }

  #sidebar_form {
    border-right: none;
    border-top: var(--border-light);
    box-shadow: 0 -5px 8px rgb(0 0 0 / 40%), 0 -1px 0 black;
    display: none;
  }

  #pattern_select_panel {
    padding-inline-start: 8px;
  }

  .dialog_sidebar_form #sidebar_form {
    display: block;
  }

  .dialog_sidebar_form #player {
    display: none !important;
  }

  .buttons_dialog {
    width: 100vw;
  }

  .small_only {
    display: inherit !important;
  }

  .circular_btn:not(.active):hover {
    background: transparent;
  }
}

@media only screen and (min-width: 601px) {
  .large_only {
    display: inherit !important;
  }
}



/*# sourceMappingURL=index.650977c7.css.map */
