@media only print {
  .noprint {
    display: none;
  }

  main {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'canvas';
  }

  canvas, svg {
    height: 100%;
  }
}
